//
// Component:       Variables
// Description:     Defines common values which are used across all components
//
// ========================================================================

// Breakpoints
// ========================================================================

// Phone Portrait:   Galaxy (360x640), iPhone 6 (375x667), iPhone 6+ (414x736)
// Phone Landscape:  Galaxy (640x360), iPhone 6 (667x375), iPhone 6+ (736x414)
// Tablet Portrait:  iPad (768x1024), Galaxy Tab (800x1280),
// Tablet Landscape: iPad (1024x768), iPad Pro (1024x1366),
// Desktop:          Galaxy Tab (1280x800), iPad Pro (1366x1024)

@deprecated: false;

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@breakpoint-small: 640px; // Phone landscape
@breakpoint-medium: 960px; // Tablet Landscape
@breakpoint-large: 1200px; // Desktop
@breakpoint-xlarge: 1600px; // Large Screens

@breakpoint-xsmall-max: (@breakpoint-small - 1);
@breakpoint-small-max: (@breakpoint-medium - 1);
@breakpoint-medium-max: (@breakpoint-large - 1);
@breakpoint-large-max: (@breakpoint-xlarge - 1);

// Global variables
// ========================================================================

//
// Typography
//

@global-font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
@global-font-size: 14px;
@global-line-height: 1.5; // 24px

@global-2xlarge-font-size: 2.625rem; // 42px
@global-xlarge-font-size: 2rem; // 32px
@global-large-font-size: 1.5rem; // 24px
@global-medium-font-size: 1.25rem; // 20px
@global-small-font-size: 0.875rem; // 14px

//
// Colors
//
@global-color: #2e3151;
@global-emphasis-color: #2e3151;
@global-muted-color: #3f4697;
@global-link-color: #3f4697;
@global-link-hover-color: #2e3151;
@global-inverse-color: #fff;

//
// Backgrounds
//
@global-background: #fff;
@global-muted-background: #f8f8f8;
@global-primary-background: #2e3151;
@global-secondary-background: #161e31;
@global-success-background: #3f4697;
@global-warning-background: #faa05a;
@global-danger-background: #f0506e;

//
// Borders
//
@global-border-width: 1px;
@global-border: #2e3151;

//
// Box-Shadows
//
@global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
@global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
@global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
@global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);

//
// Spacings
//

// Used in margin, section, list
@global-margin: 20px;
@global-small-margin: 10px;
@global-medium-margin: 40px;
@global-large-margin: 70px;
@global-xlarge-margin: 140px;

// Used in grid, column, container, align, card, padding
@global-gutter: 30px;
@global-small-gutter: 15px;
@global-medium-gutter: 40px;
@global-large-gutter: 70px;

//
// Controls
//

@global-control-height: 40px;
@global-control-small-height: 30px;
@global-control-large-height: 55px;

//
// Z-index
//

@global-z-index: 1000;

// Base
@import "../node_modules/uikit/src/less/components/mixin.less";
@import "../node_modules/uikit/src/less/components/base.less";

// Elements
@import "../node_modules/uikit/src/less/components/link.less";
@import "../node_modules/uikit/src/less/components/heading.less";
@import "../node_modules/uikit/src/less/components/divider.less";
@import "../node_modules/uikit/src/less/components/list.less";
@import "../node_modules/uikit/src/less/components/icon.less";
@import "../node_modules/uikit/src/less/components/button.less";

// Layout
@import "../node_modules/uikit/src/less/components/section.less";
@import "../node_modules/uikit/src/less/components/container.less";
@import "../node_modules/uikit/src/less/components/grid.less";
@import "../node_modules/uikit/src/less/components/card.less";

// Common
@import "../node_modules/uikit/src/less/components/close.less"; // After: Icon
@import "../node_modules/uikit/src/less/components/overlay.less"; // After: Icon

// Navs
@import "../node_modules/uikit/src/less/components/nav.less";
@import "../node_modules/uikit/src/less/components/navbar.less"; // After: Card, Grid, Nav, Icon, Search
@import "../node_modules/uikit/src/less/components/slidenav.less"; // After: Icon
@import "../node_modules/uikit/src/less/components/dotnav.less";

// JavaScript
@import "../node_modules/uikit/src/less/components/drop.less"; // After: Card
@import "../node_modules/uikit/src/less/components/dropdown.less"; // After: Card
@import "../node_modules/uikit/src/less/components/lightbox.less";
@import "../node_modules/uikit/src/less/components/slideshow.less";
@import "../node_modules/uikit/src/less/components/sticky.less";
@import "../node_modules/uikit/src/less/components/offcanvas.less";

// Additional
@import "../node_modules/uikit/src/less/components/placeholder.less";
@import "../node_modules/uikit/src/less/components/progress.less";

// Utilities
@import "../node_modules/uikit/src/less/components/animation.less";
@import "../node_modules/uikit/src/less/components/width.less";
@import "../node_modules/uikit/src/less/components/height.less";
@import "../node_modules/uikit/src/less/components/text.less";
@import "../node_modules/uikit/src/less/components/column.less";
@import "../node_modules/uikit/src/less/components/cover.less";
@import "../node_modules/uikit/src/less/components/background.less";
@import "../node_modules/uikit/src/less/components/align.less";
@import "../node_modules/uikit/src/less/components/svg.less";
@import "../node_modules/uikit/src/less/components/utility.less";
@import "../node_modules/uikit/src/less/components/flex.less"; // After: Utility
@import "../node_modules/uikit/src/less/components/margin.less";
@import "../node_modules/uikit/src/less/components/padding.less";
@import "../node_modules/uikit/src/less/components/position.less";
@import "../node_modules/uikit/src/less/components/transition.less";
@import "../node_modules/uikit/src/less/components/visibility.less";
@import "../node_modules/uikit/src/less/components/inverse.less";

// Need to be loaded last
@import "../node_modules/uikit/src/less/components/print.less";

.title-background {
  height: 20px;
  background-image: url("line.svg");
}

.uk-navbar-nav {
  margin-right: -15px;
}

.uk-navbar-nav > li > a {
  color: #343434;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  position: relative;
  z-index: 1;
}

.uk-navbar-nav > li.uk-active > a::before,
.uk-navbar-nav > li:hover > a::before,
.uk-navbar-nav > li > a.uk-open::before,
.uk-navbar-nav > li > a:focus::before {
  right: 15px;
  background-color: #2e3151;
}

.uk-navbar-nav > li > a::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  bottom: 25px;
  height: 2px;
  background-color: transparent;
  transition: 0.4s ease-in-out;
  transition-property: background-color, border-color, box-shadow, height, right;
  left: 15px;
  right: calc(102% - 15px);
  background-image: linear-gradient(100deg, #2e3151 15%, #3f4697 85%);
}

.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a.uk-open,
.uk-navbar-nav > li > a:focus {
  color: #2e3151;
  outline: 0;
}

.uk-light,
.uk-section-primary:not(.uk-preserve-color),
.uk-section-secondary:not(.uk-preserve-color) .uk-card-primary.uk-card-body,
.uk-card-primary > :not([class*="uk-card-media"]),
.uk-card-secondary.uk-card-body,
.uk-card-secondary > :not([class*="uk-card-media"]),
.uk-overlay-primary,
.uk-offcanvas-bar {
  color: #fff;
}

.uk-light hr,
.uk-light .uk-hr,
.uk-section-primary:not(.uk-preserve-color) hr,
.uk-section-primary:not(.uk-preserve-color) .uk-hr,
.uk-section-secondary:not(.uk-preserve-color) hr,
.uk-section-secondary:not(.uk-preserve-color) .uk-hr,
.uk-card-primary.uk-card-body hr,
.uk-card-primary.uk-card-body .uk-hr,
.uk-card-primary > :not([class*="uk-card-media"]) hr,
.uk-card-primary > :not([class*="uk-card-media"]) .uk-hr,
.uk-card-secondary.uk-card-body hr,
.uk-card-secondary.uk-card-body .uk-hr,
.uk-card-secondary > :not([class*="uk-card-media"]) hr,
.uk-card-secondary > :not([class*="uk-card-media"]) .uk-hr,
.uk-overlay-primary hr,
.uk-overlay-primary .uk-hr,
.uk-offcanvas-bar hr,
.uk-offcanvas-bar .uk-hr {
  border-top-color: #fff;
}

.uk-light .uk-heading-line > ::before,
.uk-light .uk-heading-line > ::after,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::before,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::before,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-card-primary.uk-card-body .uk-heading-line > ::before,
.uk-card-primary.uk-card-body .uk-heading-line > ::after,
.uk-card-primary > :not([class*="uk-card-media"]) .uk-heading-line > ::before,
.uk-card-primary > :not([class*="uk-card-media"]) .uk-heading-line > ::after,
.uk-card-secondary.uk-card-body .uk-heading-line > ::before,
.uk-card-secondary.uk-card-body .uk-heading-line > ::after,
.uk-card-secondary > :not([class*="uk-card-media"]) .uk-heading-line > ::before,
.uk-card-secondary > :not([class*="uk-card-media"]) .uk-heading-line > ::after,
.uk-overlay-primary .uk-heading-line > ::before,
.uk-overlay-primary .uk-heading-line > ::after,
.uk-offcanvas-bar .uk-heading-line > ::before,
.uk-offcanvas-bar .uk-heading-line > ::after {
  border-bottom-color: #fff;
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #fff;
}

.uk-light .uk-slidenav,
.uk-section-primary:not(.uk-preserve-color) .uk-slidenav,
.uk-section-secondary:not(.uk-preserve-color) .uk-slidenav,
.uk-card-primary.uk-card-body .uk-slidenav,
.uk-card-primary > :not([class*="uk-card-media"]) .uk-slidenav,
.uk-card-secondary.uk-card-body .uk-slidenav,
.uk-card-secondary > :not([class*="uk-card-media"]) .uk-slidenav,
.uk-overlay-primary .uk-slidenav,
.uk-offcanvas-bar .uk-slidenav {
  color: #fff;
}

.uk-light .uk-dotnav > * > *,
.uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > *,
.uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > *,
.uk-card-primary.uk-card-body .uk-dotnav > * > *,
.uk-card-primary > :not([class*="uk-card-media"]) .uk-dotnav > * > *,
.uk-card-secondary.uk-card-body .uk-dotnav > * > *,
.uk-card-secondary > :not([class*="uk-card-media"]) .uk-dotnav > * > *,
.uk-overlay-primary .uk-dotnav > * > *,
.uk-offcanvas-bar .uk-dotnav > * > * {
  border: 1px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.4);
}

.uk-dotnav > * > * {
  width: 15px;
  height: 15px;
}

.uk-navbar-dropdown-grid > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px solid #e5e5e5;
}

.uk-section-primary,
.uk-card-primary {
  background: #2e3151;
  background: -moz-linear-gradient(-45deg, #2e3151 15%, #3f4697 85%);
  background: -webkit-linear-gradient(-45deg, #2e3151 15%, #3f4697 85%);
  background: linear-gradient(135deg, #2e3151 15%, #3f4697 85%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e3151', endColorstr='#3f4697', GradientType=1);
}

.uk-width-small {
  width: 190px;
}

.uk-width-medium {
  width: 245px;
}

.uk-card-default {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

::selection {
  background: none;
  color: unset;
}

::-moz-selection {
  background: none;
  color: unset;
}
